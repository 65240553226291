import styles from "./ChordChordBanner.module.scss";

export default function ChordChordBanner() {
  return (
    <section className={styles.container}>
      <div className={styles.banner}>
        <div className={styles.body}>
          {/* <h2 className={styles.title}>
            Learn To Write <b>Chord Progressions</b> 
            <br />
            <span>
              <span>
                <b>That Make Hits!</b>
              </span>
            </span>
          </h2> */}
          <a
            href="https://start.chordchord.com/quiz-start?utm_source=guitar-tuner&utm_medium=banner&utm_content=make-songs-in-style-of"
            target="_blank"
          >
            <img
              src="/guitar-tuner-in-style-of.jpg"
              alt="ChordChord Quizе"
              className={styles.imgCenter}
            />
          </a>

          
          {/* <a
            href="https://start.chordchord.com/quiz-start?utm_source=guitar-tuner&utm_medium=banner&utm_content=make-songs-in-style-of"
            className={styles.btn}
            target="_blank"
          >
            <span>LEARN MORE</span>
          </a> */}
          {/* <div className={styles.name}>ChordChord</div> */}
        </div>
      </div>
    </section>
  );
}
